import moment from 'moment'

export const normalizeDate = (date, type, format=null) => {
    let sformat = format ? format : 'D MMMM YYYY H:mm:ss'
    if(type === 'onlyDay'){ sformat = 'D MMMM YYYY'}

    return typeof date === 'string'
        ? moment(date).format(sformat)
        : moment.unix(date).format(sformat)
}

export const addTime = (date, seconds) => {
    return moment(date).add('seconds',seconds)
}

export default normalizeDate
