import React from 'react';
import Header from "../components/Header";

const Events = () => {
    return (
        <div>
            <Header /> 
            <h1>Ивенты</h1>
        </div>
    );
};

export default Events;