export default [
    {
        name: 'Все',
        value: -1
    },
    {
        name: 'Кассы',
        value: 1,
    },
    {
        name: 'Партнеры',
        value: 2,
    }
];
