export default [
  /*  {
        name: 'Все',
        id: -1,
    },*/
    {
        name: 'Списание',
        id: 0
    },
    {
        name: 'Начисление',
        id: 1,
    }
];
