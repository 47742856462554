export default [
    {
        text: '0.4% - 1%',
        value: 0.4,
    },
    {
      text: '0.8% - 2%',
      value: 0.8
    },
    {
        text: '1.2% - 3%',
        value: 1.2
    },
    {
        text: '1.6% - 4%',
        value: 1.6
    },
    {
        text: '2%-5%',
        value: 2
    },
    {
        text: '3%-7.5%',
        value: 3,
    },
    {
        text: '4%-10%',
        value: 4,
    },
    {
        text: '5%-12.5%',
        value: 5,
    }
];
